<template>
  <div class="login-container">
    <div class="header-box" />
    <!-- 注册界面 -->
    <div class="sign-in">
      <div class="sign-in-title">用户注册</div>
      <el-form ref="userInfo" :rules="rules" :model="userInfo">
        <el-form-item prop="phone">
          <el-input v-model="userInfo.phone" class="sign-input" type="text" placeholder="手机号码">
            <svg-icon
              slot="prefix"
              style="margin-top: 15px; "
              name="phone"
              color="#1d1d1f"
              width="18"
              height="18"
            />
          </el-input>
        </el-form-item>
        <el-form-item prop="captcha">
          <el-input v-model="userInfo.captcha" class="sign-input top" type="text" placeholder="验证码">
            <svg-icon
              slot="prefix"
              style="margin-top: 15px; "
              name="idcode"
              color="#1d1d1f"
              width="18"
              height="18"
            />
            <el-button slot="suffix" class="forget-pwd" type="text" :disabled="disabled" @click="getIdCode">{{
              btnText
            }}
            </el-button>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input v-model="userInfo.password" class="sign-input top" type="password" placeholder="密码">
            <svg-icon
              slot="prefix"
              style="margin-top: 15px; "
              name="password"
              color="#1d1d1f"
              width="18"
              height="18"
            />
          </el-input>
        </el-form-item>
        <el-form-item prop="repeat">
          <el-input v-model="userInfo.repeat" class="sign-input top" type="password" placeholder="确认密码">
            <svg-icon
              slot="prefix"
              style="margin-top: 15px; "
              name="password"
              color="#1d1d1f"
              width="18"
              height="18"
            />
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="sign-button" @click="register('userInfo')">
            <span class="btn-text">提交注册</span>
          </el-button>
        </el-form-item>
        <div class="register-notice">
          我有账号，
          <span class="register" @click="loginAccount">开始登录</span>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import '@/icons/phone'
import '@/icons/password'
import '@/icons/idcode'
import { getCaptcha, sendRegister } from '@/api/login'

export default {
  data() {
    // 自定义密码复杂度验证
    var validatePassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else if (value.length < 8 || !(/[A-Za-z]/.test(value)) || !(/\d/.test(value))) {
        callback(new Error('密码长度至少为8位，并且必须包含字母、数字'))
      } else {
        this.$refs.userInfo.validateField('repeat')
        callback()
      }
    }
    var validatePassword2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.userInfo.password) {
        callback(new Error('两次输入密码不一致'))
      } else {
        callback()
      }
    }

    return {
      userInfo: {
        phone: '',
        captcha: '',
        password: '',
        repeat: ''
      },

      // 获取验证码设置
      disabled: false,
      interval: null,
      totalCount: 0,

      rules: {
        phone: [{ required: true, message: '请输入电话号码', trigger: 'blur' }],
        captcha: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        password: [{ validator: validatePassword, trigger: 'blur' }],
        repeat: [{ validator: validatePassword2, trigger: 'blur' }]
      },
      textColor: '#1d1d1f'
    }
  },
  computed: {
    btnText() {
      // console.log('totalcount', typeof(this.totalCount))
      return this.totalCount !== 0 ? `${this.totalCount}秒再次获取` : '获取验证码'
    }
  },
  methods: {
    // 用户注册
    register(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.userInfo)
          sendRegister(this.userInfo).then(() => {
            this.$message.success('注册成功！')
            this.$router.push('/login/signin')
          })
        } else {
          this.$message.warning('信息填写有误')
        }
      })
      console.log('register')
    },
    // 获取验证码
    getIdCode() {
      console.log('get Id Code')
      console.log(this.userInfo)
      this.disabled = true
      this.totalCount = 60
      getCaptcha(this.userInfo.phone).then(() => {
        this.$message.success('发送成功')
      })
      // 倒计时重发验证码
      this.interval = setInterval(() => {
        this.totalCount--
        if (this.totalCount === 0) {
          clearInterval(this.interval)
          this.disabled = false
        }
      }, 1000)
    },
    // 进行账号注册
    loginAccount() {
      console.log('login acctoun')
      this.$router.push('signin')
    }
  }
}
</script>

<style scoped>
.header-box {
  height: 60px;
}

.login-container {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  /* border: 1px solid white; */
  /* -webkit-box-align: center; */
}

.sign-in {
  position: relative;
  width: 300px;
  /* height: 200px; */
  margin: auto;
  /* -webkit-box-align: center; */
  color: white;
  /* border: 1px solid white; */
}

.sign-in-title {
  height: 50px;
  text-align: center;
  font-weight: 800;
  font-size: 22px;
  color: #1d1d1f;
}

.top {
  margin-top: 20px;
}

.sign-input {
  /* position: relative; */
  /* height: 60px; */
  font-size: 16px;
}

.sign-input /deep/ .el-input__inner {
  border: none;
  height: 50px;
  border-bottom: 1px solid #1d1d1f;
  padding-left: 40px;
  border-radius: 0px;
  background-color: transparent;
  color: #1d1d1f;
}

.sign-button {
  width: 100%;
  /* font-weight: 800; */
  height: 50px;
  margin-top: 20px;
}

.btn-text {
  font-weight: 800;
}

.forget-pwd {
  font-size: 13px;
  margin-top: 5px;
  color: #1d1d1f;
}

.forget-pwd:hover {
  cursor: pointer;
}

.register-notice {
  margin-top: 23px;
  font-size: 13px;
  text-align: center;
  color: #1d1d1f;
}

.register {
  border-bottom: 1px solid #1d1d1f;
}

.register:hover {
  cursor: pointer;
}

.el-button--text {
  color: #1d1d1f;
}

.el-button--text.is-disabled {
  color: rgba(255, 255, 255, 0.6);
}
</style>
